//
// Badge
//

.badge {
  display: inline-flex;
  align-items: center;

  // Fixed size
  &.badge-circle,
  &.badge-square {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $badge-size;
    min-width: $badge-size;
    padding: 0 0.1rem;
    line-height: 0;
  }

  // Circle
  &.badge-circle {
    border-radius: 50%;
    padding: 0;
    min-width: unset;
    width: $badge-size;
  }

  // Sizes
  &.badge-sm {
    min-width: $badge-size-sm;
    font-size: $badge-font-size-sm;

    &.badge-square {
      height: $badge-size-sm;
    }

    &.badge-circle {
      width: $badge-size-sm;
      height: $badge-size-sm;
    }
  }

  &.badge-lg {
    min-width: $badge-size-lg;
    font-size: $badge-font-size-lg;

    &.badge-square {
      height: $badge-size-lg;
    }

    &.badge-circle {
      width: $badge-size-lg;
      height: $badge-size-lg;
    }
  }
}

@each $name, $value in $theme-colors {
  .badge-#{$name} {
    color: #252F4A;
    background-color: var(--#{$prefix}#{$name});

    &.badge-outline {
      border: 1px solid var(--#{$prefix}#{$name});
      color: var(--#{$prefix}#{$name});
      background-color: transparent;
    }
  }

  .badge-light-#{$name} {
    color: var(--#{$prefix}#{$name});
    background-color: var(--#{$prefix}#{$name}-light);
  }
}

.shimmer {

  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  width: 90px;

}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}

.badge-success{
  color: var(--bs-success-inverse);
    background-color: #17C653;
}